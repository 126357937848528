/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { StepsContainer, ConnectingLine, StepBox, ConnectingLine2 } from '../RegistrationForm/styles';

import { getUserInformation, getAreaOfInterests, getLongTermGoals } from '../../redux/actions';
import { withTranslation } from 'react-i18next';

class FormWizardSteps extends Component {

  componentDidMount() {
    const { getUserInformation, getAreaOfInterests, getLongTermGoals } = this.props;
    getUserInformation();
    getAreaOfInterests();
    getLongTermGoals();
  }

  navigateUser = (step) => {
    const { history } = this.props;
    history.push(`/user/step${step}`);
  };

  enableClick = (touched, userData, locationDetails, departmentDetails) => (
    (((touched && (Object.keys(touched).length === 0 ||
      Object.keys(touched).length < 5)) &&
      this.checkUserData()) || (locationDetails && locationDetails.length > 0 && !userData['location'])
      || (departmentDetails && departmentDetails.length > 0 && !userData['department']))
  )

  checkUserData = () => {
    const { userData, locationDetails, departmentDetails } = this.props;
    if (!locationDetails && !departmentDetails) {
      return Object.keys(userData).length < 6;
    } else if (!locationDetails || !departmentDetails) {
      return Object.keys(userData).length < 7;
    } else if (locationDetails && departmentDetails) {
      return Object.keys(userData).length < 8;
    } else {
      return Object.keys(userData).length < 6;
    }
  }

  render() {
    const { areaOfInterest, longTerm, activeStep, userData, errors, touched,
      locationDetails, departmentDetails,t} = this.props;
    return (
      <StepsContainer>
        <StepBox
          active={activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4}
          onClick={() => this.navigateUser(1)}
          disabled={_.isNull(userData)}
        >
          <div>1</div>
          <h1>{t("Login Credentials")}</h1>
        </StepBox>
        <ConnectingLine >< img src='/public/images/stepArrow.svg'></img> </ConnectingLine>
        <ConnectingLine2 >< img src='/public/images/bluearrow2.svg'></img> </ConnectingLine2>

        <StepBox
          active={activeStep === 2 || activeStep === 3 || activeStep === 4}
          disabled={
            errors && Object.keys(_.omitBy(errors, _.isNil)).length > 0 ||
            this.enableClick(touched, userData, locationDetails, departmentDetails
            )
          }
          onClick={() => this.navigateUser(2)}>
          <div>2</div>
          <h1>{t("Wellness Interest")}</h1>
        </StepBox>
        <ConnectingLine >  < img src={ activeStep === 2 || activeStep === 3 || activeStep === 4?   '/public/images/stepArrow.svg' : '/public/images/stepArrow2.svg'}></img> </ConnectingLine>
        <ConnectingLine2 >  < img src={ activeStep === 2 || activeStep === 3 || activeStep === 4?    '/public/images/bluearrow2.svg' : '/public/images/arrowgray2.png'}></img> </ConnectingLine2>


        <StepBox
          active={activeStep === 3 || activeStep === 4}
          disabled={areaOfInterest.length < 1 || (errors && Object.keys(_.omitBy(errors, _.isNil)).length > 0 ||
            this.enableClick(touched, userData, locationDetails, departmentDetails))}
          onClick={() => this.navigateUser(3)}>
          <div>3</div>

          <h1>{t("Wellness Goal")}</h1>
        </StepBox>
        <ConnectingLine >  < img src={  activeStep === 3 || activeStep === 4?   '/public/images/stepArrow.svg' : '/public/images/stepArrow2.svg'}></img> </ConnectingLine>
        <ConnectingLine2 >  < img src={  activeStep === 3 || activeStep === 4?   '/public/images/bluearrow2.svg' : '/public/images/arrowgray2.png'}></img> </ConnectingLine2>

        <StepBox
          active={activeStep === 4}
          disabled={longTerm.length < 1 || (errors && Object.keys(_.omitBy(errors, _.isNil)).length > 0 ||
            this.enableClick(touched, userData, locationDetails, departmentDetails)) || areaOfInterest.length < 1}
          onClick={() => this.navigateUser(4)}>
          <div>4</div>
          <h1>{t("Legal Updates")}</h1>
        </StepBox>

      </StepsContainer>
    )
  }
}

FormWizardSteps.propTypes = {
  getUserInformation: PropTypes.func.isRequired,
  getAreaOfInterests: PropTypes.func.isRequired,
  getLongTermGoals: PropTypes.func.isRequired,

  userData: PropTypes.object.isRequired,
  areaOfInterest: PropTypes.array.isRequired,
  longTerm: PropTypes.array.isRequired,

  activeStep: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,

  errors: PropTypes.object,
  touched: PropTypes.object,

  exercise_day_per_week: PropTypes.string.isRequired,
  smoke: PropTypes.string.isRequired,
  average_sleep: PropTypes.string.isRequired,
  average_water_intake: PropTypes.string.isRequired,
  pain_experience: PropTypes.string.isRequired,
  prescription_intake: PropTypes.string.isRequired,
  physical_exam_frequency: PropTypes.string.isRequired,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  userType: PropTypes.number,
  t:PropTypes.func
};

const mapStateToProps = (state) => ({
  userData: state.register.userData,
  areaOfInterest: state.register.aoi,
  longTerm: state.register.long_term,
  exercise_day_per_week: state.register.exercise_day_per_week,
  smoke: state.register.smoke,
  average_sleep: state.register.average_sleep,
  average_water_intake: state.register.average_water_intake,
  pain_experience: state.register.pain_experience,
  prescription_intake: state.register.prescription_intake,
  physical_exam_frequency: state.register.physical_exam_frequency,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  userType: state.register.user_type
});

const mapDispatchToProps = (dispatch) => ({
  getAreaOfInterests: () => dispatch(getAreaOfInterests()),
  getLongTermGoals: () => dispatch(getLongTermGoals()),
  getUserInformation: () => dispatch(getUserInformation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation() (FormWizardSteps));