import { Modal } from 'react-bootstrap';
import styled, {css} from 'styled-components';
const MediumFont = 'Rubik-Medium';

const FieldContainer = styled.div`
  width: 100%;
  float: left;
  padding: 25px 38px 25px 40px;
`;

const Heading = styled.div`
  width: 100%;
  font-family: 'Rubik-Medium';
  font-size: 30px;
  line-height: 30px;
  color: #0D4270;
  text-transform: capitalize;
  display: flex;
  text-align: initial;
  @media only screen and (max-width: 600px) {
    font-size: 24px; 
  }
`;

const Text = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 55px;
.greyText{
  font-family: 'Rubik';
  font-size: 18px;
  line-height: 24px;
  color: rgba(189, 189, 189, 1);
  text-transform: capitalize;
  @media only screen and (max-width: 600px) {
    font-size: 15px; 
  }
}
.blueText{
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  color: rgba(105, 194, 255, 1);
  text-transform: capitalize;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    font-size: 15px; 
  }
}
.redText{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: #FD7175;
    text-transform: capitalize;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      font-size: 16px; 
    }
}
@media only screen and (max-width: 600px) {
  margin-bottom:35px;
}
`;

const FormHeading = styled.div`
width: 100%;
display: flex;
margin-bottom 10px;
justify-content: space-between;
.heading{
  font-family: 'Rubik-Medium';
  font-size: 16px;
  line-height: 20px;
  color: #0D4270;
  text-transform: capitalize;
}
.newPassword{
  font-family: 'Rubik-Medium';
  font-size: 16px;
  line-height: 20px;
  color: #FD7175;
  text-transform: capitalize;
  cursor: pointer;
}
`;

const FormWrapper = styled.div`
width: 100%;
display: block;
`;

const InputContainer = styled.div`
  margin: 10px 0px 25px 0px;
  position: relative;
  background-color: rgba(156, 156, 156, 0.05);
  &:hover, &:focus, &:active {
    outline: none;
    background-color: rgba(156, 156, 156, 0.05);
  }
  @media (max-width: 500px) {
    margin: 20px 0 30px;
  }
  .input-field-icon {
    position: absolute;
    left: 15px;
    line-height: 47px;
    font-size: 16px;
    color: rgb(153,153,153);
    top: 0
  }
  .eye-icon {
    position: absolute;
    right: 15px;
    line-height: 47px;
    font-size: 16px;
    color: rgb(153,153,153);
    top: 0;
    cursor: pointer;
  }
  > img {
    position: absolute;
    left: 15px;
    top: 14px;
  }

  > ul {
    position: absolute;
  }
`;

const CustomInput = styled.input`
  width: 100%;
  padding: 15px 10px 15px 15px;
  border-radius: 6px;
  background-color: rgba(156, 156, 156, 0.05);
  border: 1px solid rgba(156, 156, 156, 0.4);
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  
  &:hover, &:focus, &:active {
    outline: none;
    background-color: rgba(156, 156, 156, 0.05);
  }
  
  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const Button = styled.button`
width: 100%;
display: flex;
height: 50px;
background: ${({background})=> background ? "#69C2FF" : '#FD7175'};
text-align: center;
align-items: center;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
color: #FFFFFF;
cursor: pointer;
border-radius: 6px;
justify-content: center;
outline: none;
border: none;
margin-top: 35px;
text-transform: capitalize;
${({disabled}) => disabled && css`
cursor: not-allowed !important;
opacity: 0.5;
`}

@media (max-width: 800px) {
  margin-bottom: 50px;
}
`;

const ForgetCompany = styled.div`
margin-top:25px;

.text{
  color: #FD7175;
text-align: center;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
text-decoration-line: underline;
cursor:pointer;
}
`


const StyledBody = styled(Modal.Body)`
  padding: 0px;
`;
const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 360px;
  }
  .modal-content{
    border-radius: 6px;
  }
  .modal-header{
    background:linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
    border-radius:6px 6px 0px 0px;
    height:50px;
    .close{
      color:white;
      opacity:inherit;
    }
    >div{
      text-align:center;
    }
    span{
      color:white;
      font-weight:500;
      font-size:18px;
      line-height:24px;
      font-family:${MediumFont}
    }
  }
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .info-popup { 
    top: 30%;
    width: 485px;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
 @media (min-width: 768px) {
  .modal-content{
    margin-left: ${({height}) => height ? "-150px" : '0px' };
    margin-top: ${({height}) => height ? "-150px" : '0px' };;
  }
}
`;

const Info = styled.div`
  width: 100%;

  ${'' /* padding: 0px 25px 25px; */}

  .desc{
    margin-top:25px;
    display:flex;
    justify-content:center
  }

  .text{
    width:389px;
    color: #002F47;
text-align: center;
font-family: Rubik-Regular;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
.req{
  color: #FD7175;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
}
  }

  .que{
    font-size: 16px;
    opacity: 0.8;
    color:#0D4270;
    margin: 20px 0px 15px 0px;
    font-weight:500;
    line-height:24px
    font-family: ${MediumFont};
    text-align:center
  }
  .btns{
    display:flex;
    justify-content:center;
    font-family: ${MediumFont};
    padding:25px 0px;
    .btn1{
      display:flex;
      justify-content:center;
      align-items:center;
      margin-right:15px;
      border-radius: 3px;
      background: #69C2FF;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
      color:white;
      border:none;
      width:200px;
      text-decoration:none;
      height:50px;
      text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
    }

    .btn2{
      border-radius: 3px;
border: 1px solid #9C9C9C;
box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
      background:white;
      color:#9C9C9C;
      width:200px;
      height:50px;
      text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
    }
  }
`;

export { FieldContainer, Heading, Text, FormHeading, FormWrapper, CustomInput, InputContainer, Button,ForgetCompany, StyledBody,StyledModal, Info };